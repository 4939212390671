import * as React from "react"
import "./scss/footer.scss"
import { Link } from "gatsby"

export default function Footer(props) {
	return (
		<nav className="footer">
			<div className="logo-container">
				<Link to="/"><img src={'/images/long_logo.png'} alt="logo" className="footer__logo" /></Link>
			</div>
			<div className="footer-links-container">
				<Link to="." className="footer-link">Back to Top</Link>
				{/*<Link to="/privacy" className="footer-link">Privacy Policy</Link>
				<Link to="/terms" className="footer-link">Terms And Conditions</Link>*/}
				<Link to="/contact" className="footer-link">Contact Us</Link>
			</div>
		</nav>
	)
}